<template>
  <div class="manual" v-loading="loading">
    <div class="title">{{ i18nData.title }}</div>
    <div class="content" v-html="pageInfo.content">
      <!-- <span
        >A voluntary group composed of teachers and students from Tianjin
        University decorated Tanchang county in Gansu province by painting walls
        covering an area of hundreds of square meters. The wall paintings mainly
        followed the themes of rural, industrial, and ecological revitalization,
        which was agreed upon by the county in prior communications. "Our
        volunteers have been designing the contents of the paintings since June.
        They learned about the county's representative industries beforehand and
        then included local products like Sichuan pepper, noodles, mushrooms and
        Chinese medicinal herbs into their paintings,”said Li Xia, a TJU
        official in charge of the university's summer volunteer programs.
        50c5c08522d174f674d7c4339095eb3.png Wang Runyan, an undergraduate
        majoring in animation felt quite excited to use what she has learnt to
        improve the culture and environment of the countryside. "In Chela town
        of Tanchang county, together with my team members, we have painted 12
        walls. Each wall took five to six hours to paint on average. But it was
        well worth the effort!”Wang said. Tianjin University has paired up with
        Tanchang county in poverty alleviation and rural vitalization over many
        years. For both summer and winter vacations, the university organizes
        voluntary groups to carry out activities like voluntary teaching or
        cultural activities in Tanchang country.</span
      >
      <img src="./../../public/img/manual/img1.jpg" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "manual",
  data() {
    return {
      pageInfo:{},
      loading:true
    };
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getContent();
  },
  methods: {
    async getContent() {
      try {
        let params = {
          name:'manual'
        }
        let res = await this.$api.https.getpages(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.pageInfo = res.data.pageInfo;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.manual {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  padding: 70px 60px;
  .title {
    color: #282828;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .content {
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 28px;
    span {
      color: #888;
    }
    img {
      align-self: center;
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
